<template>
  <div class="ws-table">
    <div class="header">
      <slot name="header">
        <div class="title">{{ title ? $t(title) : '' }}</div>
        <div
          class="handler"
          style="text-align: right; margin-top: 5px; margin-bottom: 10px"
        >
          <slot name="headerHandler"></slot>
        </div>
      </slot>
    </div>
    <el-table
      ref="generalTable"
      :data="listData"
      border
      stripe
      @selection-change="handleSelectionChange"
      style="width: 100%"
      :row-style="{ height: '48px' }"
      :cell-style="{ padding: '0' }"
    >
      <el-table-column
        v-if="showSelectColumn"
        type="selection"
        align="center"
        width="60"
        fixed="left"
        :selectable="checkboxIsDisable"
      ></el-table-column>
      <el-table-column
        v-if="showIndexColumn"
        type="index"
        :label="$t('general.seq')"
        align="center"
        :index="currentPageIndex"
        width="80"
        fixed="left"
      ></el-table-column>
      <template v-for="(propItem, index) in colList" :key="index">
        <el-table-column
          v-if="isShowCol(propItem.prop) || isHandlerCol(propItem)"
          v-bind="propItem"
          :label="$t(propItem.label)"
          show-overflow-tooltip
          :align="isHandlerCol(propItem) ? 'left' : 'center'"
          :fixed="isHandlerCol(propItem) ? 'right' : false"
          :minWidth="labelWidth(propItem.label)"
        >
          <template #default="scope">
            <template v-if="propItem.formatter">
              <div>
                <!-- 数组拆解并依次进行格式化 -->
                {{ propItem.formatter(scope.row[propItem.prop]) }}
              </div>
            </template>
            <template v-else>
              <slot
                :name="propItem.slotName"
                :row="scope.row"
                :field="propItem.prop"
              >
                {{ scope.row[propItem.prop] }}
              </slot>
            </template>
          </template>
        </el-table-column>
      </template>
      <el-table-column
        v-if="showColSetting"
        align="center"
        width="40"
        fixed="right"
      >
        <template #header>
          <el-popover
            placement="bottom"
            :title="$t('general.col-setting')"
            :width="200"
            trigger="click"
          >
            <div>
              <el-checkbox
                v-model="checkAll"
                :indeterminate="isIndeterminate"
                @change="handleCheckAllChange"
                >{{ $t('general.all') }}</el-checkbox
              >
              <el-checkbox-group
                class="col-checkBox"
                v-model="checkedCol"
                @change="handleCheckedColChange"
              >
                <draggable
                  v-model="colList"
                  animation="1000"
                  @start="onStart"
                  @end="onEnd"
                  itemKey="prop"
                >
                  <template #item="{ element }">
                    <el-checkbox
                      v-if="!isHandlerCol(element)"
                      class="col-check"
                      :key="element.prop"
                      :label="element.prop"
                      >{{ $t(element.label) }}</el-checkbox
                    ></template
                  >
                </draggable>
              </el-checkbox-group>
            </div>
            <template #reference>
              <span class="col-setting-text">...</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <slot name="footer">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30]"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listCount"
        >
        </el-pagination>
      </slot>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  ref,
  defineExpose,
  watch,
  computed,
  nextTick
} from 'vue'
import { cloneDeep } from 'lodash'
import { getItem, setItem } from '@/utils/storage'
import { isSameItem } from '@/utils/util'
import draggable from 'vuedraggable'
import i18n from '@/i18n'

const props = defineProps({
  listData: {
    type: Array,
    require: true
  },
  listCount: {
    type: Number,
    default: 0
  },
  propList: {
    type: Array,
    required: true
  },
  showIndexColumn: {
    type: Boolean,
    default: false
  },
  showSelectColumn: {
    type: Boolean,
    default: false
  },
  // 用于是否展示列控制菜单
  showColSetting: {
    type: Boolean,
    default: false
  },
  // 用于记住table勾选列有哪些，当需要有列控制功能时必传
  tableColCheckedListName: {
    type: String,
    default: ''
  },
  // 用于记住table所有列的排序顺序，当需要有列控制功能时必传
  tableColAllListName: {
    type: String,
    default: ''
  },
  page: {
    type: Object,
    default: () => ({ currentPage: 1, pageSize: 10 })
  },
  childrenProps: {
    type: Object,
    default: () => ({})
  },
  showFooter: {
    type: Boolean,
    default: true
  },
  title: {
    type: String,
    default: ''
  },
  // 设置是否只能勾选一条数据
  isLimitSelectOne: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:page'])
const currentSelection = ref([])
const generalTable = ref('')
const { t } = i18n.global

const handleSelectionChange = (val) => {
  // 表格选中数据接收
  currentSelection.value = val
  // 当设置只能勾选一条数据时
  if (props.isLimitSelectOne) {
    props?.listData?.length &&
      props.listData.forEach((item) => {
        if (val[val.length - 1] === item) {
          generalTable.value.toggleRowSelection(item, true)
        } else {
          generalTable.value.toggleRowSelection(item, false)
        }
      })
  }
}

const checkedCol = ref([])
const colList = ref([])
const checkAll = ref(true)
const isIndeterminate = ref(false)
const isHandlerCol = (item) => {
  return item.slotName === 'handler'
}
const isShowCol = (col) => {
  return checkedCol.value.includes(col)
}

const handleCheckAllChange = (val) => {
  checkedCol.value = val ? colList.value.map((item) => item.prop) : []
  isIndeterminate.value = false
  setItem(props.tableColCheckedListName, checkedCol.value)
}

const handleCheckedColChange = (value) => {
  const checkedCount = value.length
  checkAll.value = checkedCount === colList.value.length
  isIndeterminate.value =
    checkedCount > 0 && checkedCount < colList.value.length
  setItem(props.tableColCheckedListName, checkedCol.value)
}

// 当传入表格的数据项disabled属性为true时则该行的checkBox禁用
const checkboxIsDisable = (row) => {
  return !row.disabled
}

const initCheckBoxStatus = () => {
  // 当传入表格的数据项isChecked属性为true时则该行的checkBox为已勾选状态
  nextTick(() => {
    props?.listData?.length &&
      props.listData.forEach((row) => {
        if (row.isChecked) {
          generalTable.value.toggleRowSelection(row, true)
        }
      })
  })
}

const labelWidth = computed(() => {
  return (label) => {
    // 需要国际化后的label长度
    const transLabel = t(label)
    const l = transLabel.length
    const f = 10
    const minWidth = f * (l + 2) // 加上一个文字长度
    return minWidth
  }
})

const drag = ref(false)
// 开始拖拽事件
const onStart = () => {
  drag.value = true
}
// 拖拽结束事件
const onEnd = (value) => {
  setItem(props.tableColAllListName, colList.value)
}
const handleCurrentChange = (currentPage) => {
  emit('update:page', { ...props.page, currentPage })
}
const handleSizeChange = (pageSize) => {
  emit('update:page', { ...props.page, pageSize })
}

const currentPageIndex = (index) => {
  return index + (props.page.currentPage - 1) * props.page.pageSize + 1
}

watch(
  () => props.propList,
  (newVal, oldVal) => {
    if (newVal) {
      const tableColList = cloneDeep(newVal)
      const storageColCheckList = getItem(props.tableColCheckedListName) || []
      const storageColAllList = getItem(props.tableColAllListName) || []
      // 比较storageColAllList和tableColList的length及每一项是否相同原因是当修改过table propList配置项时，避免读取localstorage的旧配置数据
      colList.value =
        storageColAllList &&
        storageColAllList.length &&
        storageColAllList.length === tableColList.length &&
        isSameItem(storageColAllList, tableColList)
          ? storageColAllList
          : tableColList
      checkedCol.value =
        storageColCheckList && storageColCheckList.length
          ? storageColCheckList
          : tableColList
              .filter((item) => !isHandlerCol(item))
              .map((item) => item.prop)
    }
  },
  { immediate: true, deep: true }
)

watch(
  () => props.listData,
  () => {
    initCheckBoxStatus()
  },
  { immediate: true, deep: true }
)

defineExpose({
  currentSelection
})
</script>

<style lang="scss" scoped>
.col-checkBox {
  margin-left: 20px;
  .col-check {
    display: flex;
    line-height: 35px;
  }
}
.col-setting-text {
  &:hover {
    cursor: pointer;
  }
}

.footer {
  margin-top: 15px;
  .el-pagination {
    text-align: right;
  }
}
</style>
