<template>
  <div class="page-content">
    <ws-table
      :listData="dataList"
      :listCount="dataCount"
      v-bind="contentTableConfig"
      v-model:page="pageInfo"
      ref="selectList"
    >
      <!-- 1、头部中的插槽 -->
      <template #headerHandler>
        <el-button
          v-if="isCreate && isShowCreateSlot"
          type="info"
          size="small"
          icon="el-icon-plus"
          @click="handleNewClick"
        >
          {{ $t('general.create') }}
        </el-button>
        <el-button
          v-if="isDelete && isShowDeleteSlot"
          type="danger"
          size="small"
          icon="el-icon-delete"
          @click="handleDeleteSelection"
        >
          {{ $t('general.delete') }}
        </el-button>
        <el-button
          v-if="isImport && isShowImportSlot"
          type="success"
          size="small"
          icon="el-icon-plus"
          @click="handleImportClick"
        >
          {{ $t('general.import') }}
        </el-button>
        <el-button
          v-if="isConfig && isShowConfigSlot"
          size="small"
          icon="el-icon-setting"
          @click="handleConfigClick"
        >
          Configure Template
        </el-button>
      </template>
      <!-- 创建时间插槽 -->
      <template #formateTime="scope">
        <span>
          {{ $filters.fmtDateTime(scope.row[scope.field]) }}
        </span>
      </template>
      <template #formateDate="scope">
        <span>
          {{ $filters.fmtDate(scope.row[scope.field]) }}
        </span>
      </template>
      <template #formatZeroTime="scope">
        <span>
          {{ $filters.getCurrentSystemTimeFormat(scope.row[scope.field]) }}
        </span>
      </template>
      <template #handler="scope">
        <div class="operation-btns">
          <el-button
            class="opt-btn"
            v-if="isShowViewBtn"
            icon="el-icon-view"
            size="mini"
            type="success"
            plain
            @click="handleViewClick(scope.row)"
            >{{ $t('general.view') }}</el-button
          >
          <el-button
            class="opt-btn"
            v-if="isShowEditBtn && isUpdate"
            icon="el-icon-edit"
            size="mini"
            type="primary"
            plain
            @click="handleEditClick(scope.row)"
            >{{ $t('general.edit') }}</el-button
          >

          <!-- 不常用的操作按钮使用otherHandler插槽 -->
          <slot name="otherHandler" :row="scope.row"></slot>

          <el-button
            class="opt-btn"
            v-if="isShowDeleteBtn && isDelete"
            icon="el-icon-delete"
            size="mini"
            type="danger"
            plain
            @click="handleDeleteClick(scope.row)"
            >{{ $t('general.delete') }}</el-button
          >
          <!-- 更多下拉菜单按钮 -->
          <slot name="moreHandler" :row="scope.row"></slot>
        </div>
      </template>

      <!-- 在page-content中动态插入剩余的插槽 -->
      <template
        v-for="item in otherPropSlots"
        :key="item.prop"
        #[item.slotName]="scope"
      >
        <template v-if="item.slotName === 'amount'">
          <span>
            {{ formateNumber(scope.row[item.prop]) }}
          </span>
        </template>
        <template v-if="item.slotName === 'loadImg'">
          <el-image
            :src="scope.row[item.prop]"
            alt="Logo File"
            style="width: 150px; height: 60px"
            :preview-src-list="[scope.row[item.prop]]"
          />
        </template>
        <template v-if="item.slotName && item.slotName !== 'amount'">
          <slot :name="item.slotName" :row="scope.row"></slot>
        </template>
      </template>
    </ws-table>
    <div class="extra-btns" style="text-align: center">
      <slot name="extraHandleBtns"></slot>
    </div>
  </div>
</template>

<script setup>
import WsTable from '@/base-ui/table/src/index.js'
import { usePermission } from '@/hooks/use-permission'
import { ElMessage, ElMessageBox } from 'element-plus'
import i18n from '@/i18n'
import { formateNumber } from '@/utils/util'

import {
  defineProps,
  defineEmits,
  ref,
  watch,
  computed,
  defineExpose,
  getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'
const props = defineProps({
  contentTableConfig: {
    type: Object,
    required: true
  },
  pageName: {
    type: String,
    required: true
  },
  pageAction: {
    type: String,
    required: true
  },
  pageCountGetter: {
    type: String,
    required: true
  },
  pageListGetter: {
    type: String,
    required: true
  },
  moduleName: {
    type: String,
    required: true
  },
  isShowCreateSlot: {
    type: Boolean,
    default: true
  },
  isShowDeleteSlot: {
    type: Boolean,
    default: false
  },
  isShowImportSlot: {
    type: Boolean,
    default: false
  },
  isShowConfigSlot: {
    type: Boolean,
    default: false
  },
  deleteRecordAction: {
    type: String,
    default: ''
  },
  deleteSelectionAction: {
    type: String,
    default: ''
  },
  isNeedBaseTableQuery: {
    type: Boolean,
    default: true
  },
  isShowEditBtn: {
    type: Boolean,
    default: true
  },
  isShowDeleteBtn: {
    type: Boolean,
    default: true
  },
  pageInitialQuery: {
    type: Object,
    default: () => {}
  },
  isShowViewBtn: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits([
  'newBtnClick',
  'deleteBtnClick',
  'editBtnClick',
  'viewBtnClick'
])
const store = useStore()
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

// 获取页面操作的权限
const isCreate = usePermission(props.moduleName, props.pageName, 'create')
const isUpdate = usePermission(props.moduleName, props.pageName, 'edit')
const isDelete = usePermission(props.moduleName, props.pageName, 'delete')
const isImport = usePermission(props.moduleName, props.pageName, 'import')
const isConfig = usePermission(props.moduleName, props.pageName, 'push')

// 双向绑定pageInfo
const pageInfo = ref({ currentPage: 1, pageSize: 10 })
watch(pageInfo, () => {
  emit('pageInfoChange')
})
// 获取表格内容数据
const getPageData = async (queryInfo = {}) => {
  // TODO后端规范问题
  let baseTableQuery = {}
  if (props.isNeedBaseTableQuery) {
    baseTableQuery = {
      currentPage: pageInfo.value.currentPage,
      pageSize: pageInfo.value.pageSize, // 兼容后端
      start: (pageInfo.value.currentPage - 1) * pageInfo.value.pageSize
    }
  }
  await store.dispatch(props.pageAction, {
    ...baseTableQuery,
    ...queryInfo
  })
}

getPageData(props.pageInitialQuery)

// 从vuex中获取数据
const dataList = computed(() =>
  store.getters[props.pageListGetter](props.pageName)
)
const dataCount = computed(() =>
  store.getters[props.pageCountGetter](props.pageName)
)

// 获取其他动态插槽的名称
let otherPropSlots = []
if (props.contentTableConfig.propList) {
  otherPropSlots = props.contentTableConfig.propList.filter((item) => {
    if (item.slotName === 'formateTime') return false
    if (item.slotName === 'formateDate') return false
    if (item.slotName === 'handler') return false
    if (item.slotName === 'formatZeroTime') return false
    return true
  })
}
// 增删改查操作
const handleNewClick = () => {
  emit('newBtnClick')
}

const handleEditClick = (item) => {
  emit('editBtnClick', item.id)
}

const handleImportClick = () => {
  emit('importBtnClick')
}

const handleConfigClick = () => {
  emit('configBtnClick')
}
const { t } = i18n.global
const handleDeleteClick = (item) => {
  ElMessageBox.confirm(
    t('popup.global-delete-tip'),
    t('popup.confirm-warning-title'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      // 调用请求
      handleMessageCommit(props.deleteRecordAction, item.id).then(() => {
        getPageData(props.pageInitialQuery)
        // 关闭tagsView中的相关涉及到的页签
        store.commit('app/removeTagsByCurrentId', item.id)
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: t('popup.cancel-delete-tip')
      })
    })
}
const selectList = ref()

const handleDeleteSelection = async () => {
  if (
    selectList.value.currentSelection &&
    !selectList.value.currentSelection.length
  ) {
    ElMessage.error(t('general.select-delete-data'))
    return
  }
  ElMessageBox.confirm(
    t('popup.global-batch-delete-tip'),
    t('popup.confirm-warning-title'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      const delList = []
      const object = JSON.parse(
        JSON.stringify(selectList.value.currentSelection)
      )
      object.forEach((item) => {
        delList.push(item.id)
      })
      handleMessageCommit(props.deleteSelectionAction, delList).then(() => {
        getPageData(props.pageInitialQuery)
      })
    })
    .catch((action) => {
      if (action === 'cancel') {
        ElMessage({
          type: 'info',
          message: t('popup.cancel-delete-tip')
        })
      }
    })
}

const getCurrentSectionList = () => {
  return selectList.value.currentSelection
}

const handleViewClick = (item) => {
  // 这里做下特殊处理
  emit('viewBtnClick', item.id)
}

defineExpose({
  getPageData,
  handleDeleteClick,
  getCurrentSectionList
})
</script>

<style scoped lang="scss">
.page-content {
  border: 1px solid #e9e9eb;
  padding: 10px 15px;
  border-radius: 5px;
  .opt-btn {
    margin: 5px;
  }
}
</style>
